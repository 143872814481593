const serviceRoot = process.env.REACT_APP_SERVICE_ROOT;

export const serviceFetch = async (url, method, body) => {
    let csrftoken = "";
    if (document.cookie) {
        let splitted = document.cookie.split("; ")
        let aux = splitted.find((row) => row.startsWith("csrftoken="));
        csrftoken = aux.split("=")[1];
    }


    if(csrftoken === ""){
        csrftoken = localStorage.getItem("csrf_token");
    }
    
    let params = {
        method: method,
        body: body,
        credentials: "include",
        headers: {
            "X-CSRFToken": csrftoken,
            // "SameSite": "Secure"
        },
        mode: "cors"
    };
    return fetch(`${serviceRoot}/${url}`, params);
}

export const serviceHttpRequest = async (url, params, uploaded = null, uploadprogress = null, mimetype="multipart/form-data") => {
    // should use XMLHttpRequest
    return new Promise((resolve, reject) => {
        let csrftoken = "";
        if (document.cookie) {
            let splitted = document.cookie.split("; ")
            let aux = splitted.find((row) => row.startsWith("csrftoken="));
            csrftoken = aux.split("=")[1];
        }

        if(csrftoken === ""){
            csrftoken = localStorage.getItem("csrf_token");
        }

        let xhr = new XMLHttpRequest();
        xhr.open(params.method, `${serviceRoot}/${url}`, true);
        xhr.overrideMimeType(mimetype);
        xhr.setRequestHeader("X-CSRFToken", csrftoken);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.withCredentials = true;
        if (uploadprogress) {
            xhr.upload.onprogress = uploadprogress;
        }
        if (uploaded) {
            xhr.upload.onloadend = uploaded;
        }
        xhr.onload = function () {
            if (Math.floor(xhr.status/100) === 2) {
                let response = JSON.parse(xhr.responseText);
                resolve(response);
            } else {
                console.log("Error: " + xhr.status);
                reject(new Error(xhr.responseText));
            }
        };
        xhr.onerror = function () {
            console.log("Error: " + xhr.status);
            reject(new Error(xhr.status));
        };
        xhr.send(params.body);
    });
};