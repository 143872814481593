import React from "react";

import "../styles/views/TarifarioView.css";
import aranceles_chilevoces from "../assets/tarifado/a-2023-chv.pdf";
import glosario_chilevoces from "../assets/tarifado/Glosario Chilevoces 2022.pdf";
import tarifario_cr from "../assets/tarifado/Tarifario Costa Rica Loc Comercial.jpeg";

const TarifarioView = () => {
    return <div className="TarifarioView">
        <h1>Tarifas Recomendadas por las Asociaciones de Locutores</h1>
        <p>En GoDemos, entendemos la importancia de tener una tarifa justa y competitiva para garantizar una competencia equilibrada en la industria. Por eso, hemos recopilado las tarifas recomendadas por las principales asociaciones de locutores en Latinámerica.</p>
        <p>A continuación, se presentan las tarifas recomendadas por cada asociación:</p>
        <table className="listado">
            <thead>
                <tr>
                    <th>Asociación</th>
                    <th>Documento</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Aranceles Chile Voces</td>
                    <td><a href={aranceles_chilevoces} target="_blank" rel="noreferrer">Ver Documento</a></td>
                </tr>
                <tr>
                    <td>Glosario Chile Voces</td>
                    <td><a href={glosario_chilevoces} target="_blank" rel="noreferrer">Ver Documento</a></td>
                </tr>
                <tr>
                    <td>Tarifario Costa Rica</td>
                    <td><a href={tarifario_cr} target="_blank" rel="noreferrer">Ver Documento</a></td>
                </tr>
            </tbody>
        </table>
        <p><b>Importante:</b> Es importante mencionar que estas tarifas son solo recomendadas por las asociaciones y pueden variar según la región, el tipo de proyecto y otros factores. Los locutores deben investigar y verificar las tarifas actuales en su área antes de proporcionar servicios.</p>
    </div>
        ;
};

export default TarifarioView;